<form #updateForm="ngForm" (ngSubmit)="actualizar(updateForm)" >
    <div class="card box-shadow-sm">           
        <div class="card-body">
            <div class="row">
                <div class="col-xl-8 col-sm-7">
                    <label for="">Titulo</label>
                    <input type="text" class="form-control" placeholder="Titulo de banner" required name="titulo" [(ngModel)]="banner.titulo">
                </div>                    
            </div>
            <div class="row">
                <div class="col-xl-8 col-sm-7">
                    <label for="">Texto 1</label>                    
                    <textarea class="form-control" placeholder="Texto 1" required rows="5" name="texto1" [(ngModel)]="banner.texto1"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-8 col-sm-7">
                    <label for="">Texto 2</label>                    
                    <textarea class="form-control" placeholder="Texto 2" required rows="5" name="texto2" [(ngModel)]="banner.texto2"></textarea>
                </div>
            </div>
            <div class="row">   
                <div class="col-md-8 form-group">
                    <div class="row">
                        <div class="col-lg-12 form-group">	
                            <label for="">Imagen</label>
                            <div class="custom-file">
                                <input class="custom-file-input" type="file" id="file-input" (change)="fileChangeEvent($event)">
                                <label class="custom-file-label" id="input-portada" for="file-input" style="white-space: nowrap;overflow: hidden;">1920X718</label>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <img [src]="imgSelect" class="img-thumbnail" alt="Rounded image">
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        <div class="card-footer">
            <button *ngIf="!load_btn" class="btn btn-primary mr-3" type="submit">Actualizar banner</button>
            <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                Actualizando...
            </button>
            <a class="btn btn-warning" [routerLink]="['/banners/index-banner']">Regresar</a>
        </div>
    </div> 
</form>
