<div class="card box-shadow-sm">   

    <div class="card-body">
        <div style="max-width: 48rem;">
            <div class="row mb-5">
                <div class="col-md-4" style="padding-right: 0px;">
                    <img [src]="url+'obtener_portada/'+producto.portada" class="img-thumbnail rounded-0" alt="Square image">
                </div>
                <div class="col-md-8" style="padding-left: 0px;">
                    <div class="card" style="height: 100% !important;background: #f5eeee;">
                        <div class="card-body">
                            <h3 class="card-product-title text-truncate mb-2">
                                <a class="nav-link mb-3" >{{producto.titulo}}</a>
                                <p class="text-muted">{{producto.descripcion}}</p>
                                <div class="d-flex align-items-center">
                                    <span class="h5 d-inline-block mb-0 text-danger">${{producto.precio}}</span>                                    
                                </div>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Título de la variedad</label>
                <div class="col-md-9">
                  <input class="form-control" required type="text" id="text-input" placeholder="Título de la variedad" name="titulo_variedad" [(ngModel)]="producto.titulo_variedad">
                </div>
            </div>
        
        <div class="form-group row align-items-center">
            <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Variedades</label>
            <div class="col-md-9">
                <div class="input-group">
                    <input class="form-control" type="text" placeholder="Nueva variedad" name="nueva_variedad" [(ngModel)]="nueva_variedad">
                    <button class="btn btn-primary" type="button" (click)="agregar_variedad()">Agregar</button>
                </div>
    
            </div>
            
            <div class="col-md-3">

            </div>
            <div class="col-md-9">
            
            <div class="table-responsive mt-3">
                <table class="table table-sm" *ngIf="producto.variedades">
                <thead class="thead-dark">
                    <tr>                
                        <th>Título</th>                    
                        <th>Eliminar</th>                    
                    </tr>
                </thead>
                <ng-container *ngIf="producto.variedades.length == 0">
                    <tbody>
                        <tr>
                            <td colspan="2" class="text-center">
                                <span class="text-muted">No hay categorias disponibles</span>
                            </td>
                            
                        </tr>
                        </tbody>
                </ng-container>
                <ng-container *ngIf="producto.variedades.length>=1">
                    <tbody *ngFor="let item of producto.variedades; let indice = index">
                        <tr>
                            <td>{{item.titulo}}</td>
                            <td style="cursor: pointer;" class="text-danger" (click)="eliminar_variedad(indice)">[Eliminar]</td>
                        </tr>
                        </tbody>
                </ng-container>
                </table>
            </div>
            </div>
            
        </div>
        
        </div>
        
    </div>
    <div class="card-footer">
        <button *ngIf="!load_btn" class="btn btn-primary mr-3" (click)="actualizar()">Guardar cambios</button>
        <button *ngIf="load_btn" type="button" class="btn btn-secondary">
            <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
            Guardando...
        </button>
    </div>
</div>