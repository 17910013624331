import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AgGridModule } from '@ag-grid-community/angular';

import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxTinymceModule } from 'ngx-tinymce';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';


import { AuthModule } from './components/auth/auth.module';

import { ClientesModule } from './components/clientes/clientes.module';

import { ProductosModule } from './components/productos/productos.module';
import { ContactoModule } from './components/contacto/contacto.module';
import { BannersModule } from './components/banners/banners.module';
import { BlogsModule } from './components/blogs/blogs.module';




@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [    
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DashboardModule,
    ContactoModule,    
    AuthModule,
    SharedModule,    
    AgGridModule,
    ClientesModule,
    BannersModule, 
    BlogsModule,   
    ProductosModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,    
    HttpClientModule,    
    NgbPaginationModule,
    NgxTinymceModule.forRoot({
      baseURL:'../../../assets/tinymce/'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
