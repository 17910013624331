<!-- <div class="card box-shadow-sm" *ngIf="producto == undefined">
    <div class="card-body vendor-table">
      <h3 class="text-center">No se encontró el registro</h3>
        <div class="row">
            <div class="col-3 mx-auto">
                <img src="assets/img/advertencia.png" alt="" style="width: 100%;">        
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="container-fluid" *ngIf="producto">
    <div class="card"> 
        <div class="card-header">
            <h5 style="margin-bottom: 0px;">Inventario de {{producto.titulo}}</h5>
            <div class="row">
                <div class="col-12">
                    <form #inventarioForm="ngForm" (ngSubmit)="registro_inventario(inventarioForm)" class="form-inline">
                        <label class="sr-only">Cantidad</label>
                        <input class="form-control mb-3 mr-sm-4" type="text" placeholder="Cantidad" name="cantidad" [(ngModel)]="inventario.cantidad">
        
                        <label class="sr-only">Proveedor</label>
                        <input class="form-control mb-3 mr-sm-4" type="text" placeholder="Proveedor" name="proveedor" [(ngModel)]="inventario.proveedor">
                        
                        <button class="btn btn-primary mb-3 mr-3" type="submit">Ingresar</button>
                        
                        <button class="btn btn-success mb-3" type="button" (click)="download_excel()">Exportar a excel</button>
                    </form>
                </div>
            </div>
        </div>     
        <div class="card-body vendor-table">
            <div class="custom-datatable vendor-list">
                <div class="table-responsive ">                  
                    <table class="table table-striped">                
                        <thead>
                            <tr>
                                <th scope="col">Admin</th>
                                <th scope="col">cantidad</th>
                                <th scope="col">Proveedor</th>
                                <th scope="col">OP</th>                            
                            </tr>
                        </thead>
                        
                        <tbody *ngIf="inventarios.length == 0">
                            <tr>
                                <td scope="row">
                                    <span class="text-muted">No hay registros de inventario</span>
                                </td>
                            </tr>
                        </tbody>
                        
                        <tbody *ngFor="let item of inventarios">
                            <tr>
                                <td>{{item.admin.nombres}} {{item.admin.apellidos}}</td>
                                <td>{{item.cantidad}}</td>
                                <td>{{item.proveedor}}</td>
                                <td>
                                    <a style="cursor: pointer;" data-toggle="modal" data-original-title="test" data-target="#exampleModal" (click)="open(content)" [attr.data-target]="'#delete-'+item._id"><i class="fa fa-trash-o"></i></a>
    
                                    <ng-template #content let-modal>
                                        <div class="modal-header">
                                            <h5 class="modal-title f-w-600" id="exampleModalLabel">Eliminar registro</h5>
                                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
                                        </div>
                                        <div class="modal-body">                                          
                                            <p><strong>¿Desea eliminar el registro?</strong></p>
                                            <p>Toda la información asociada a este registro va a ser eliminada por completo.
                                            <span class="text-danger">Esta operación no se puede deshacer.</span>
                                            </p>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar</button>
                                            <button *ngIf="!load_btn" class="btn btn-primary btn-shadow btn-sm" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                                            
                                            <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                                                <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                Eliminando...
                                            </button>
                                        </div>
                                    </ng-template>
    
                                </td>
                            </tr>
                        </tbody>
                    </table>                   
                    
                </div>
            </div>
        </div>
        
    </div>
  </div> -->

  <div class="container-fluid">
      <ng-container *ngIf="producto == undefined">
        <div class="card">
            <div class="card-header">
                <h3 class="text-center">No se encontró el registro</h3>
            </div>
            <div class="card-body">                
                <div class="row">
                    <div class="col-3 mx-auto">
                        <img src="assets/img/advertencia.png" alt="" style="width: 100%;">        
                    </div>
                </div>                
            </div>
        </div>
      </ng-container>
      <ng-container *ngIf="producto">
        <div class="card">
            <div class="card-header">
                <h5>{{producto.titulo}}</h5>
            </div>
            <div class="card-body">
                <div class="btn-popup pull-left">
                    <form #inventarioForm="ngForm" (ngSubmit)="registro_inventario(inventarioForm)" class="form-inline">
                        <label class="sr-only">Cantidad</label>
                        <input class="form-control mb-3 mr-sm-4" type="text" placeholder="Cantidad" name="cantidad" [(ngModel)]="inventario.cantidad">
        
                        <label class="sr-only">Proveedor</label>
                        <input class="form-control mb-3 mr-sm-4" type="text" placeholder="Proveedor" name="proveedor" [(ngModel)]="inventario.proveedor">
                        
                        <button class="btn btn-primary mb-3 mr-3" type="submit">Ingresar</button>
                        
                        <button class="btn btn-success mb-3" type="button" (click)="download_excel()">Exportar a excel</button>
                    </form>
                </div>
                <div class="custom-datatable">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" >Admin</th>
                                    <th scope="col" >Cantidad</th>
                                    <th scope="col">Proveedor</th>
                                    <th scope="col">OP</th>
                                    
                                </tr>
                            </thead>
                            <tbody >                                
                                <tr *ngFor="let item of inventarios | slice: (page-1)*pageSize : (page-1) * pageSize + pageSize; let indice = index">
                                    <td>
                                        {{item.admin.nombres}} {{item.admin.apellidos}}
                                    </td>
                                    <td>
                                        {{item.cantidad}}
                                    </td>
                                    <td>
                                        {{item.proveedor}}
                                    </td>
                                    <td>
                                        <a style="cursor: pointer;" data-toggle="modal" data-original-title="test" data-target="#exampleModal" (click)="open(content)" [attr.data-target]="'#delete-'+item._id"><i class="fa fa-trash-o"></i></a>
                                        <ng-template #content let-modal>
                                            <div class="modal-header">
                                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Eliminar registro</h5>
                                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
                                            </div>
                                            <div class="modal-body">                                          
                                                <p><strong>¿Desea eliminar el registro?</strong></p>
                                                <p>Toda la información asociada a este registro va a ser eliminada por completo.
                                                <span class="text-danger">Esta operación no se puede deshacer.</span>
                                                </p>
                                            </div>
                                            <div class="modal-footer">
                                                <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancelar</button>
                                                <button *ngIf="!load_btn" class="btn btn-primary btn-shadow btn-sm" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                                                
                                                <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                                                    <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                    Eliminando...
                                                </button>
                                            </div>
                                        </ng-template>
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table> 
                        <div class="d-flex justify-content-center p-2">
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="inventarios.length">
                            </ngb-pagination>
                         </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
      </ng-container>
    
</div>
