import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [

		{
			title: 'Blogs', icon: 'camera', type: 'sub', active: false, children: [
				{ path: '/blogs/index-blog', title: 'Blog', type: 'link' },
				{ path: '/blogs/create-blog', title: 'Crear Blog', type: 'link' },				
			]
		},	
		{
			title: 'Banners', path: '/banners/index-banner', icon: 'settings', type: 'link', active: false
		},	
		{
			title: 'Clientes', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/clientes/index-cliente', title: 'Clientes', type: 'link' },
				{ path: '/clientes/create-cliente', title: 'Crear Cliente', type: 'link' },				
			]
		},
		{
			title: 'Productos', icon: 'box', type: 'sub', active: false, children: [
				{ path: '/productos/index-producto', title: 'Productos', type: 'link' },
				{ path: '/productos/create-producto', title: 'Crear Producto', type: 'link' },				
			]
		},
		{
			title: 'Programas', icon: 'camera', type: 'sub', active: false, children: [
				{ path: '/productos/index-programa', title: 'Programas', type: 'link' },
				{ path: '/productos/create-programa', title: 'Crear Programa', type: 'link' },				
			]
		},

		{
			title: 'Mensaje', path: '/contacto', icon: 'clipboard', type: 'link', active: false
		},

		{
			title: 'Ventas', path: '/ventas/index-venta', icon: 'dollar-sign', type: 'link', active: false
		},	

		{
			title: 'Configuraciones', path: '/config/index-config', icon: 'settings', type: 'link', active: false
		},
				
		
		
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
