import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactoComponent } from './contacto.component';
import { AdminGuard } from 'src/app/guards/admin.guard';


const routes: Routes = [
  {
    path: '', component: ContactoComponent, canActivate: [AdminGuard],
    data: {
      title: "Mensajes",
      breadcrumb: "Mensajes"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactoRoutingModule { }
