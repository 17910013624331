import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { GLOBAL } from "./GLOBAL";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  public url;

  constructor(
    private _http: HttpClient,
  ) {
    this.url = GLOBAL.url;
  }

  listar_blogs_admin(filtro,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.get(this.url+'listar_blogs_admin/'+filtro,{headers:headers});
  } 

  eliminar_blog_admin(id,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.delete(this.url+'eliminar_blog_admin/'+id,{headers:headers});
  } 

  registro_blog_admin(data,file,token):Observable<any>{
    let headers = new HttpHeaders({'Authorization':token});

    const fd = new FormData();
    fd.append('title',data.title);
    fd.append('text1',data.text1);
    fd.append('text2',data.text2);        
    fd.append('image1',file);    

    return this._http.post(this.url+'registro_blog_admin/',fd,{headers:headers});
  }

  add_blog_image_admin(id,file1,file2,file3,token):Observable<any>{    
    let headers = new HttpHeaders({'Authorization':token});
    const fd = new FormData();              
    fd.append('image1',file1);    
    fd.append('image2',file2);    
    fd.append('image3',file3);    
    return this._http.put(this.url+'add_blog_image_admin/'+id,fd,{headers:headers});    
  }
  

  registro_blog_data_admin(data,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.post(this.url+'registro_blog_data_admin/',data,{headers:headers});

  }
  
  obtener_blog_admin(id,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.get(this.url+'obtener_blog_admin/'+id,{headers:headers});
  }

  actualizar_blog_admin(data,id,token):Observable<any>{
    if(data.blog){
      let headers = new HttpHeaders({'Authorization':token});

      const fd = new FormData();
      fd.append('title',data.title);
      fd.append('text1',data.text1);
      fd.append('text2',data.text2);      
      fd.append('image1',data.blog);    

      return this._http.put(this.url+'actualizar_blog_admin/'+id,fd,{headers:headers});
    }else{
      let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
      return this._http.put(this.url+'actualizar_blog_admin/'+id,data,{headers:headers});
    }

  }
}
