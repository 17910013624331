<div class="card box-shadow-sm">
  <div class="card-body" *ngIf="!load_data">
    <div class="mb-4 pb-4 border-bottom" *ngFor="let item of mensajes | slice: (page-1)*pageSize : (page-1) * pageSize + pageSize;">
      <div class="row">
        <div class="col-xl-3">
          <div class="blog-left">
            <strong><h4 class="text-danger mr-1 font-weight-bold text-decoration-none">{{item.cliente}}</h4></strong>
            <h6>{{item.createdAt | date}}</h6>
            <ul class="post-social">
              <li><a class="mr-1 font-weight-bold text-decoration-none">{{item.correo}}</a></li>
              <span class="badge badge-success" *ngIf="item.estado == 'Abierto'">{{item.estado}}</span>
              <span class="badge badge-primary" *ngIf="item.estado == 'Cerrado'">{{item.estado}}</span>
            </ul>
          </div>
        </div>
        <div class="col-xl-9">
          <div class="blog-left">
            <div>
              <p>{{item.mensaje}}</p>
            </div>
            <ul class="post-social">
              <li>
                <a style="cursor: pointer;" data-toggle="modal" data-original-title="test"
                  data-target="#exampleModal" (click)="open(content)" [attr.data-target]="'#delete-'+item._id">
                  <i class="fa fa-comments"></i> Cerrar mensaje
                </a>
                <ng-template #content let-modal>
                  <div class="modal-header">
                    <h5 class="modal-title f-w-600" id="exampleModalLabel">Cerrar mensaje</h5>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p><strong>¿Desea cerrar este mensaje?</strong></p>
                  </div>
                  <div class="modal-footer">
                    <button class="btn btn-primary" (click)="cerrar(item._id)" type="button">Si, cerrar</button>
                  </div>
                </ng-template>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="load_data">
    <div class="row">
      <div class="col-12 text-center">
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-center p-2">
      <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="mensajes.length"></ngb-pagination>
    </div>
  </div>
</div>
