import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  

  //TIENDA ONLINE
  {
    path: 'blogs',
    loadChildren: () => import('../../components/blogs/blogs.module').then(m => m.BlogsModule),
    data: {
      breadcrumb: "Blogs"
    }
  },
  {
    path: 'banners',
    loadChildren: () => import('../../components/banners/banners.module').then(m => m.BannersModule),
    data: {
      breadcrumb: "Banners"
    }
  },
  {
    path: 'clientes',
    loadChildren: () => import('../../components/clientes/clientes.module').then(m => m.ClientesModule),
    data: {
      breadcrumb: "Clientes"
    }
  },
  {
    path: 'productos',
    loadChildren: () => import('../../components/productos/productos.module').then(m => m.ProductosModule),
    data: {
      breadcrumb: "Productos"
    }
  },
  {
    path: 'contacto',
    loadChildren: () => import('../../components/contacto/contacto.module').then(m => m.ContactoModule),
  },
  {
    path: 'ventas',
    loadChildren: () => import('../../components/ventas/ventas.module').then(m => m.VentasModule),
    data: {
      breadcrumb: "Ventas"
    }
  },

  {
    path: 'config',
    loadChildren: () => import('../../components/config/config.module').then(m => m.ConfigModule),
    
  },


];