import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexProductoComponent } from './index-producto/index-producto.component';
import { UpdateProductoComponent } from './update-producto/update-producto.component';
import { InventarioProductoComponent } from './inventario-producto/inventario-producto.component';
import { VariedadProductoComponent } from './variedad-producto/variedad-producto.component';
import { GaleriaProductoComponent } from './galeria-producto/galeria-producto.component';
import { CreateProductoComponent } from './create-producto/create-producto.component';
import { ReviewsProductoComponent } from './reviews-producto/reviews-producto.component';
import { CreateProgramaComponent } from './create-programa/create-programa.component';
import { IndexProgramaComponent } from './index-programa/index-programa.component';
import { UpdateProgramaComponent } from './update-programa/update-programa.component';
import { SeccionProgramaComponent } from './seccion-programa/seccion-programa.component';

import { AdminGuard } from "../../guards/admin.guard";


const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'index-producto', component: IndexProductoComponent, canActivate: [AdminGuard], 
        data: { title: "Productos", breadcrumb: "Productos" } 
      },      
      { path: 'update-producto/:id', component: UpdateProductoComponent, canActivate: [AdminGuard], 
        data: {title: "Editar Producto", breadcrumb: "Editar Producto"}
      },      
      { path: 'inventario-producto/:id', component: InventarioProductoComponent, canActivate: [AdminGuard], 
        data: { title: "Inventario Producto", breadcrumb: "Inventario Producto" } 
      },      
      { path: 'variedad-producto/:id', component: VariedadProductoComponent, canActivate: [AdminGuard], 
        data: {title: "Variedad Producto", breadcrumb: "Variedad Producto" } 
      },      
      { path: 'galeria-producto/:id', component: GaleriaProductoComponent, canActivate: [AdminGuard], 
        data: { title: "Galeria Producto", breadcrumb: "Galeria Producto" } 
      },        
      { path: 'create-producto', component: CreateProductoComponent, canActivate: [AdminGuard], 
        data: { title: "Crear Producto", breadcrumb: "Crear Producto"}
      },      
      { path: 'reviews-producto/:id', component: ReviewsProductoComponent, canActivate: [AdminGuard],
        data: { title: "Reseñas Producto",breadcrumb: "Reseñas Producto"} 
      },            
      {path: 'index-programa', component: IndexProgramaComponent, canActivate: [AdminGuard], 
        data: { title: "Programas", breadcrumb: "Programas" }
      },
      {path: 'create-programa', component: CreateProgramaComponent, canActivate: [AdminGuard],
        data: { title: "Crear Programa", breadcrumb: "Crear programa" }
      },            
      {path: 'update-programa/:id', component: UpdateProgramaComponent, canActivate: [AdminGuard],
        data: { title: "Actualizar Programa", breadcrumb: "Actualizar programa" }
      },      
      {path: 'secciones/:id', component:SeccionProgramaComponent, canActivate: [AdminGuard], 
        data: { title: "Secciones", breadcrumb: "Secciones" }
      },
    
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ProductosRoutingModule { }
