import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from "src/app/services/admin.service";
import { Router } from "@angular/router";

import { Component, OnInit } from '@angular/core';

import { JwtHelperService } from "@auth0/angular-jwt";
var moment = require('moment');

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private _adminService:AdminService,
    private _router:Router
  ){
    
  }
 
  canActivate():any{    

    

    if(!this._adminService.isAuthenticated(['admin'])){
      console.log('ENTRO ACA linea 27')
      this._router.navigate(['/login']);
      return false; 
    } else {
      const token = localStorage.getItem('token')||'{}';   
      const helper = new JwtHelperService();
      var decodedToken = helper.decodeToken(token);
      
      const now = moment().unix();       
      
      if (now > decodedToken.exp) {      
        localStorage.removeItem('token');
        localStorage.removeItem('_id');
        this._router.navigate(['/login']);
        return false
      }  
    }
    return true;
  }
}