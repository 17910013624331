
<div class="card box-shadow-sm">     
  <div class="card-body vendor-table">
    <div class="custom-datatable vendor-list">
        <div class="table-responsive ">            
            <table class="table table-striped">
                <thead>
                    <tr>
                      <th>Portada</th>
                      <th>Título</th>                      
                      <th>OP</th>
                    </tr>
                </thead>
                <tbody *ngIf="load_data">
                  <tr>
                      <td scope="row">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                          <span class="sr-only">Loading...</span>
                        </div> 
                      </td>                            
                  </tr>
              </tbody>
              <ng-container *ngIf="!load_data">
                <ng-container *ngIf="blogs.length == 0" >
                  <tr>
                      <td colspan="5" class="text-center">
                          <span class="text-muted">No se encontraron registros</span>
                      </td>
                  </tr>
                </ng-container >
                <ng-container *ngIf="blogs.length >= 1">
                  <tbody *ngFor="let item of blogs| slice: (page-1)*pageSize : (page-1) * pageSize + pageSize">                       
                      <tr>
                          <td>
                              <h6 style="padding-bottom: 0;margin-bottom: 0;">
                                  <img [src]="url+'get_image_blog/'+item.image1" class="img-thumbnail rounded-0" alt="Square image">
                              </h6>
                          </td>
                          <td>{{item.title}}</td>                                
                          <td>
                              <div class="btn-group dropdown d-inline-block mb-3 me-2">
                                  <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">OPCIONES</button>
                                  <div class="dropdown-menu">
                                    <a class="dropdown-item" [routerLink]="['/blogs/edit-blog',item._id]" >Editar blog</a>
                                    <a style="cursor: pointer;" class="dropdown-item" data-toggle="modal" data-original-title="test"
                                        data-target="'#delete-'+item._id" (click)="open(content)">Eliminar blog
                                    </a>
                                    
                                  </div>
                              </div>

                              <ng-template #content let-modal>
                                <div class="modal-header">
                                    <h5 class="modal-title f-w-600" id="delete-{{item._id}}">Eliminar </h5>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                  <p class="fs-sm">{{item.titulo}} desea eliminar este blog?</p>
                                  <strong>{{item.nombres}}  {{item.apellidos}}</strong>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" (click)="eliminar(item._id)" class="btn btn-primary" >Eliminar</button>
                                    <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                        (click)="modal.dismiss('Cross click')">Close</button>
                                </div>
                              </ng-template>
                          </td>
                      </tr>
                  </tbody>
                </ng-container>
              
            </ng-container>
            </table>            
        </div>
    </div>
</div>    
</div>    
