import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexBlogComponent } from './index-blog/index-blog.component';
import { CreateBlogComponent } from './create-blog/create-blog.component';
import { EditBlogComponent } from './edit-blog/edit-blog.component';
import { AdminGuard } from "../../guards/admin.guard";

const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'index-blog', component: IndexBlogComponent, canActivate: [AdminGuard],
        data: {
          title: "Blogs",
          breadcrumb: "Blogs"
        }
      },
      {
        path: 'create-blog', component: CreateBlogComponent, canActivate: [AdminGuard],
        data: {
          title: "Crear Blog",
          breadcrumb: "Crear Blog"
        }
      },
      {
        path: 'edit-blog/:id', component: EditBlogComponent, canActivate: [AdminGuard],
        data: {
          title: "Editar Blog",
          breadcrumb: "Editar Blog"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class BlogsRoutingModule { }
