<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row justify-content-center">
                
                <div class="col-md-7 text-center">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                <li [ngbNavItem]="1">
                                  <a ngbNavLink>Login</a>
                                  <ng-template ngbNavContent>
                                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"
                                        class="form-horizontal auth-form" novalidate="">
                                        <div class="form-group">
                                            <input required="" formControlName="email" type="email"
                                                class="form-control" placeholder="email"
                                                id="exampleInputEmail1">
                                        </div>
                                        <div class="form-group">
                                            <input required="" formControlName="password" type="password"
                                                class="form-control" placeholder="Password">
                                        </div>
                                       
                                        <div class="form-button">
                                            <a> <button class="btn btn-primary" type="submit"> Login</button></a>
                                        </div>
                                        
                                    </form>
                                </div>
                                  </ng-template>
                                </li>
                                
                              </ul>
                              <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>