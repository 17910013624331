import { Component, CUSTOM_ELEMENTS_SCHEMA,DEFAULT_CURRENCY_CODE, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
declare var iziToast;
declare var $;

import { GLOBAL } from "src/app/services/GLOBAL";
import { ProductoService } from 'src/app/services/producto.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { NgbModule, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';



import { NgForm } from '@angular/forms';
import { response } from 'express';
import { AdminService } from '../../services/admin.service';
import { ClienteService } from '../../services/cliente.service';

import iziToast from 'izitoast/dist/js/iziToast.min.js';

import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { OrderDB, ORDERDB } from 'src/app/shared/tables/order-list';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html'  
})
export class ContactoComponent implements OnInit {

  @ViewChild('closebutton') closebutton;

  public closeResult: string;

  public mensajes : Array<any> = [];
  public load_data = true;
  public page = 1;
  public pageSize = 20;
  public filtro = '';
  public token;  
  
  public load_btn = false;

  constructor(
    private _adminService: AdminService,
    private modalService: NgbModal
  ) {
    this.token = localStorage.getItem('token');
   }

  ngOnInit(): void {
    this.init_Data();
  }

  init_Data(){
    this._adminService.obtener_mensajes_admin(this.token).subscribe({
      next: (response)=>{
        this.mensajes = response.data;
        this.load_data = false;        
      }
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public onSave() {
    this.closebutton.nativeElement.click();
  }

  cerrar(id){
    this.load_btn = true;
    this._adminService.cerrar_mensaje_admin(id,{data:undefined},this.token).subscribe({
      next: (response)=>{
          iziToast.show({
            title: 'SUCCESS',
            titleColor: '#1DC74C',
            color: '#FFF',
            class: 'text-success',
            position: 'topRight',
            message: 'Se cerró correctamente el mensaje.',        
        });

        this.modalService.dismissAll();

        this.init_Data();
        this.load_btn = false;
        
      },
      error: (error)=>{
        iziToast.show({
            title: 'ERROR',
            titleColor: '#FF0000',
            color: '#FFF',
            class: 'text-danger',
            position: 'topRight',
            message: 'Ocurrió un error en el servidor.',        
        });
        console.log(error);        
        
      }
    })
  }

}
