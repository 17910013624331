import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexBannerComponent } from './index-banner/index-banner.component';
import { CreateBannerComponent } from './create-banner/create-banner.component';
import { EditBannerComponent } from './edit-banner/edit-banner.component';
import { AdminGuard } from "./../../guards/admin.guard";

const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'index-banner', component: IndexBannerComponent, canActivate: [AdminGuard],
        data: {
          title: "Banners",
          breadcrumb: "Banners"
        }
      },
      {
        path: 'create-banner', component: CreateBannerComponent, canActivate: [AdminGuard],
        data: {
          title: "Crear Banner",
          breadcrumb: "Crear Banner"
        }
      },
      {
        path: 'edit-banner/:id', component: EditBannerComponent, canActivate: [AdminGuard],
        data: {
          title: "Editar Banner",
          breadcrumb: "Editar Banner"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class BannersRoutingModule { }
