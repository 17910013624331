import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogsRoutingModule } from './blogs-routing.module';
 import { IndexBlogComponent } from './index-blog/index-blog.component';
// import { CreateVendorsComponent } from './create-vendors/create-vendors.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateBlogComponent } from './create-blog/create-blog.component';
import { EditBlogComponent } from './edit-blog/edit-blog.component';
// import { Ng2SmartTableModule } from 'ng2-smart-table';

import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    IndexBlogComponent,
    CreateBlogComponent,
    EditBlogComponent
      //, CreateVendorsComponent
],
  imports: [
    CommonModule,
    BlogsRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    // Ng2SmartTableModule    
    FormsModule,
    Ng2SearchPipeModule,
    SharedModule,
    
  ]
})
export class BlogsModule { }