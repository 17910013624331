import { Component, DEFAULT_CURRENCY_CODE, OnInit } from '@angular/core';
import { GLOBAL } from "src/app/services/GLOBAL";
import { BlogService } from 'src/app/services/blog.service';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var iziToast;
declare var $:any;

@Component({
  selector: 'app-index-blog',
  templateUrl: './index-blog.component.html',
  //styleUrls: ['./index-blog.component.css']
})
export class IndexBlogComponent implements OnInit {

  public closeResult: string;
  public clientes : Array<any>=[];

  public load_data = true;
  public filtro = '';
  public token;
  public blogs : Array<any> = [];  
  public url;
  public page = 1;
  public pageSize = 20;

  public load_btn = false;

  constructor(
    private _blogService : BlogService,
    private modalService: NgbModal
  ) {
    this.token = localStorage.getItem('token');
    this.url = GLOBAL.url;    
  }

  ngOnInit(): void {    
    this.init_data();
  }

  init_data(){
    this._blogService.listar_blogs_admin(this.filtro, this.token).subscribe(
      response=>{        
        this.blogs = response.data;

        this.blogs.forEach(element => {
          var tt_inicio = Date.parse(element.fecha_inicio+"T00:00:00")/1000;
          var tt_fin = Date.parse(element.fecha_fin+"T00:00:00")/1000;

          var today = Date.parse(new Date().toString())/1000;
          
          if(today>tt_inicio){
            element.estado = 'Expirado';
          }
          if(today<tt_inicio){
            element.estado = 'Proximamente';
          }
          if(today>=tt_inicio && today<=tt_fin){
            element.estado = 'En progreso';
          }          
        });
        this.load_data = false;       
      }     
    )
  }


  filtrar(){
    if(this.filtro){
      this._blogService.listar_blogs_admin(this.filtro, this.token).subscribe(
        response=>{          
          this.blogs = response.data;
          this.load_data = false;          
        }
      )
    }else{
      iziToast.show({
          title: 'ERROR',
          titleColor: '#FF0000',
          color: '#FFF',
          class: 'text-danger',
          position: 'topRight',
          message: 'Los datos del formulario no son validos',        

      });
    }
  }

  resetear(){
    this.filtro = '';
    this.init_data();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  eliminar(id){
    this.load_btn = true;
    this._blogService.eliminar_blog_admin(id,this.token).subscribe(
      response=>{
        iziToast.show({
            title: 'SUCCESS',
            titleColor: '#1DC74C',
            color: '#FFF',
            class: 'text-success',
            position: 'topRight',
            message: 'Se eliminó correctamente el nuevo blog.',        
        });

      this.modalService.dismissAll('Cross click');

      this.load_btn = false;
      this.init_data();        
      },
      error=>{
        iziToast.show({
            title: 'ERROR',
            titleColor: '#FF0000',
            color: '#FFF',
            class: 'text-danger',
            position: 'topRight',
            message: 'Ocurrió un error en el servidor.',        
        });        
        this.load_btn = false;        
      }
    )
  }

}
