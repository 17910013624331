import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BannersRoutingModule } from './banners-routing.module';
 import { IndexBannerComponent } from './index-banner/index-banner.component';
// import { CreateVendorsComponent } from './create-vendors/create-vendors.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateBannerComponent } from './create-banner/create-banner.component';
import { EditBannerComponent } from './edit-banner/edit-banner.component';
// import { Ng2SmartTableModule } from 'ng2-smart-table';

import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    IndexBannerComponent,
    CreateBannerComponent,
    EditBannerComponent
      //, CreateVendorsComponent
],
  imports: [
    CommonModule,
    BannersRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    // Ng2SmartTableModule    
    FormsModule,
    Ng2SearchPipeModule,
    SharedModule,
    
  ]
})
export class BannersModule { }