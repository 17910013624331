import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexClienteComponent } from './index-cliente/index-cliente.component';
import { CreateClienteComponent } from './create-cliente/create-cliente.component';
import { EditClienteComponent } from './edit-cliente/edit-cliente.component';
import { AdminGuard } from "./../../guards/admin.guard";

const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'index-cliente', component: IndexClienteComponent, canActivate: [AdminGuard],
        data: {
          title: "Clientes",
          breadcrumb: "Clientes"
        }
      },
      {
        path: 'create-cliente', component: CreateClienteComponent, canActivate: [AdminGuard],
        data: {
          title: "Crear Cliente",
          breadcrumb: "Crear Cliente"
        }
      },
      {
        path: 'edit-cliente/:id', component: EditClienteComponent, canActivate: [AdminGuard],
        data: {
          title: "Editar Cliente",
          breadcrumb: "Editar Cliente"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ClientesRoutingModule { }
