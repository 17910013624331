<div class="card box-shadow-sm">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <h5 style="margin-bottom: 0px;">Filtro de programas</h5>
        <div class="input-group">
          <input class="form-control" type="text" placeholder="Título del programa" name="filtro" [(ngModel)]="filtro">
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" (click)="filtrar()">Filtrar</button>
            <button class="btn btn-info" type="button" (click)="resetear()">Resetear</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body vendor-table">
    <div class="custom-datatable vendor-list">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">OP</th>
              <th scope="col">Título</th>
              <th scope="col">Precio</th>
            </tr>
          </thead>
          <tbody *ngIf="load_data">
            <tr>
              <td class="text-center" colspan="3">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!load_data">
            <tr *ngFor="let item of productos | slice: (page - 1) * pageSize : (page - 1) * pageSize + pageSize">
              <td>
                <div class="btn-group dropdown d-inline-block mb-3 me-2">
                  <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-cog"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" [routerLink]="['/productos/update-programa', item._id]">Editar</a>
                    <a class="dropdown-item text-danger" style="cursor: pointer;" data-toggle="modal" data-target="#deleteModal" (click)="open(delete)" [attr.data-target]="'#delete-' + item._id">
                      Eliminar
                    </a>
                    <ng-template #delete let-modal>
                      <div class="modal-header">
                        <h5 class="modal-title f-w-600" id="deleteModalLabel">Confirmación de eliminación</h5>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <p>
                          <strong>Desea eliminar este programa:</strong>
                          <span class="text-primary">{{ item.titulo }}?</span>
                        </p>
                        <p>
                          Toda la información asociada a esta sección va a ser eliminada por completo.
                          <span class="text-danger">Esta operación no se puede deshacer.</span>
                        </p>
                      </div>
                      <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancelar</button>
                        <button *ngIf="!load_btn" class="btn btn-primary btn-shadow btn-sm" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                        <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                          <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                          Eliminando...
                        </button>
                      </div>
                    </ng-template>
                    <a class="dropdown-item" [routerLink]="['/productos/secciones', item._id]">Secciones</a>
                  </div>
                </div>
              </td>
              <td>
                <h6 style="padding-bottom: 0;margin-bottom: 0;">
                  <img style="width: 50px;" [src]="url + 'obtener_portada/' + item.portada" class="img-thumbnail rounded-0" alt="Square image">
                  {{ item.titulo }}
                </h6>
              </td>
              <td>{{ item.precio }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="productos.length"></ngb-pagination>
  </div>
</div>
