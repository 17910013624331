<form #registroForm="ngForm" (ngSubmit)="registro(registroForm)" >
    <div class="card box-shadow-sm">            
            <div class="card-body">            
                <div class="row">
                    <div class="col-md-4 form-group">
                        <label for="">Titulo de banner</label>
                        <input type="text" class="form-control" placeholder="Titulo de banner" required name="titulo" [(ngModel)]="banner.titulo">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="">Texto 1</label>
                        <input type="text" class="form-control" placeholder="Texto1 de banner" name="texto1" [(ngModel)]="banner.texto1">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="">Texto 2</label>
                        <input type="text" class="form-control" placeholder="Texto2 de banner" name="texto2" [(ngModel)]="banner.texto2">
                    </div>

                    <div class="col-md-4 form-group">
                        <label for="">Posicion</label>
                        <input type="number" class="form-control" placeholder="Posicion del banner" required name="posicion" [(ngModel)]="banner.posicion">
                    </div>
                    <div class="col-md-4 form-group">
                        
                    </div>
                    <div class="col-md-4 form-group">
                        <div class="row">
                            <div class="col-lg-12 form-group">	
                                <label for="">Banner</label>
                                <div class="custom-file">
                                  <input class="custom-file-input" type="file" id="file-input" (change)="fileChangeEvent($event)">
                                  <label class="custom-file-label" id="input-portada" for="file-input" style="white-space: nowrap;overflow: hidden;">1920 X 800 píxeles</label>
                                </div>
                            </div>
                            <div class="col-lg-12">
                              <img [src]="imgSelect" class="img-thumbnail" alt="Rounded image">
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
            <div class="card-footer">
                <button *ngIf="!load_btn" class="btn btn-primary mr-3" type="submit">Crear banner</button>
                <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                    <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                    Creando...
                </button>
                <a class="btn btn-warning" [routerLink]="['/panel/banners']">Regresar</a>
            </div>
    </div> 
</form>