<form #registroForm="ngForm" (ngSubmit)="registro(registroForm)">
    <section class="blog-detail-page section-b-space">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 blog-detail">                    
                    
                    <input type="file" (change)="fileChangeEvent($event, 'previewImage1', 'file1')" class="form-control-file">
                    <img [src]="previewImage1" class="img-fluid" alt="Preview Image 1" *ngIf="previewImage1">

                    <label for=""> <strong>Título</strong> </label>
                    <input type="text" class="form-control" placeholder="Titulo de blog" name="title" [(ngModel)]="blog.title">
                    <label for=""> <strong>Resumen:</strong> </label>
                    <textarea class="form-control" placeholder="RESUMEN" name="summary" [(ngModel)]="blog.summary" rows="4"> </textarea>
                    <label for=""> <strong>Texto 1:</strong> </label>
                    <textarea class="form-control" placeholder="TEXTO 1" name="text1" [(ngModel)]="blog.text1" rows="15"> </textarea>
                    
                </div>
            </div>
            <div class="row section-b-space blog-advance">
                <div class="col-lg-6">                    
                    <input type="file" (change)="fileChangeEvent($event, 'previewImage2', 'file2')" class="form-control-file">
                    <img [src]="previewImage2" class="img-fluid" alt="Preview Image 2" *ngIf="previewImage2">
                </div>
                <div class="col-lg-6">                                        
                    <input type="file" (change)="fileChangeEvent($event, 'previewImage3', 'file3')" class="form-control-file">
                    <img [src]="previewImage3" class="img-fluid" alt="Preview Image 3" *ngIf="previewImage3">
                </div>
                <hr>
                <div class="col-lg-6">                                    
                    <input #topicInputRef type="text" class="form-control" placeholder="Agregar tema" (keydown.enter)="handleEnterKey($event)" />
                    <ul style="list-style-type: disc;">
                        <li *ngFor="let topic of blog.quiz">
                            <strong>{{ topic }}</strong>&nbsp;
                            <a (click)="removeQuizTopic(topic)" href="javascript:void(0)">
                                <i class="fa fa-trash-o" style="color: red; font-size: 24px;"></i>
                            </a>
                        </li>
                    </ul>

                    <hr>
                    <!-----------------TAGS---------------------->
                    <div class="input-group">
                        <select class="form-control custom-select" name="tag" [(ngModel)]="selectedTag">
                            <option value="" disabled [ngValue]="null">Seleccionar Tag</option>
                            <option *ngFor="let item of config_global.tags" [ngValue]="item">{{item.title}}</option>
                        </select>
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary" (click)="addTag()">Agregar</button>
                        </div>
                    </div>                    
                    <div>                    
                        <ul style="list-style-type: disc;">
                            <li *ngFor="let tag of blog.tags">
                                <strong>{{ tag.title }}</strong>&nbsp;
                                <a (click)="removeTag(tag)" href="javascript:void(0)">
                                    <i class="fa fa-trash-o" style="color: red; font-size: 24px;"></i>
                                </a>
                            </li>
                        </ul>
                    </div>          
                </div>
                <div class="col-lg-6">
                    <label for=""> <strong>Texto 2:</strong> </label>
                    <textarea class="form-control" placeholder="TEXTO 2" name="text2" [(ngModel)]="blog.text2" rows="15"></textarea>
                </div>
            </div>        
        </div>
    </section>

    <section class="blog-detail-page section-b-space">
        <div class="container">
            <button *ngIf="!load_btn" class="btn btn-secondary mr-2" type="submit">Guardar cambios</button>
            <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Creando...
            </button>
            <a class="btn btn-primary" [routerLink]="['/blogs/index-blog']">Regresar</a>            
        </div>
    </section>
</form>