  <div class="card box-shadow-sm">
    <div class="card-header">
      <h5 style="margin-bottom: 0px;">Filtro de clientes</h5>
      <form class="form-inline pt-2">
        <div class="form-group mb-3 mr-sm-4">
          <label class="sr-only" for="inline-form-input-name">Apellidos</label>
          <input class="form-control" (keyup)="filtro('apellidos')" name="filtro_apellidos" [(ngModel)]="filtro_apellidos" type="text"
            placeholder="Apellidos">
        </div>
        <div class="form-group mb-3 mr-sm-4">
          <label class="sr-only" for="inline-form-input-username">Correo</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">@</div>
            </div>
            <input class="form-control" type="text" (keyup)="filtro('correo')" name="filtro_correo" [(ngModel)]="filtro_correo" placeholder="Correo">
          </div>
        </div>
      </form>
    </div>
    
        <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nombres</th>
                      <th>Apellidos</th>
                      <th>Correo</th>
                      <th>Ultimo acceso</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="load_data">
                    <tr>
                      <td class="text-center" colspan="5">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  
                    <tbody *ngIf="!load_data">
                      <tr *ngFor="let item of clientes| slice: (page-1)*pageSize : (page-1) * pageSize + pageSize; let indice = index">
                        <td>{{indice+1}}</td>
                        <td>{{item.nombres}}</td>
                        <td>{{item.apellidos}}</td>
                        <td>{{item.email}}</td>
                        <td> 
                          <ng-container *ngIf="item.ultimo_acceso"> {{item.ultimo_acceso |date}} </ng-container>
                          <ng-container *ngIf="!item.ultimo_acceso"> Esperando Logueo </ng-container>
                        <td>
                          <div class="btn-group dropdown d-inline-block mb-3 me-2">
                            <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">OPCIONES</button>
                            <div class="dropdown-menu">

                              


                              <a class="dropdown-item" [routerLink]="['/clientes/edit-cliente',item._id]">Editar cliente</a>
                              <a style="cursor: pointer;" class="dropdown-item" data-toggle="modal" data-original-title="test"
                            data-target="'#delete-'+item._id" (click)="open(content)">Eliminar cliente</a>

                            </div>
                          </div>

                          <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="delete-{{item._id}}">Eliminar </h5>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                              <p class="fs-sm">Desea eliminar este cliente?</p>
                              <strong>{{item.nombres}}  {{item.apellidos}}</strong>
                            </div>
                            <div class="modal-footer">
                                <button type="button" (click)="eliminar(item._id)" class="btn btn-primary" >Eliminar</button>
                                <button class="btn btn-secondary" type="button" data-dismiss="modal"
                                    (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                          </ng-template>
  
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [collectionSize]="clientes.length"
            ></ngb-pagination>

        </div>
  </div>    
