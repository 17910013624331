/* import { Component, OnInit } from '@angular/core';
import { FormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from './../../../services/admin.service';

declare var jQuery:any;
declare var $:any;
declare var iziToast;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  //styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public user : any = {};
  public usuario : any = {};
  public token : any = '';

  constructor(
    private _adminService:AdminService,
    private _router: Router
  ) {     
    this.token = this._adminService.getToken();
  }

  ngOnInit(): void {   
     
    if(this.token){
      this._router.navigate(['/']);
    }else{
      //MANTENER EN EL COMPONENTE
    }
    
  }

  login(loginForm){
    if(loginForm.valid){
      console.log(this.user);
      let data = {
        email: this.user.email,
        password: this.user.password
      }

      this._adminService.login_admin(data).subscribe(
        response=>{
          if(response.data == undefined){
            iziToast.show({
              title: 'ERROR',
              titleColor: '#FF0000',
              color: '#FFF',
              class: 'text-danger',
              position: 'topRight',
              message: response.message,
      
            });
          }else{
            this.usuario = response.data;

            localStorage.setItem('token',response.token);
            localStorage.setItem('_id',response.data._id);

            this._router.navigate(['/']);
          }
          
        },
        error=>{
          console.log(error);
        }
      );
    }else{
      iziToast.show({
        title: 'ERROR',
        titleColor: '#FF0000',
        color: '#FFF',
        class: 'text-danger',
        position: 'topRight',
        message: 'Los datos del formulario no son validos',        

      });
    }
  }

} */



import { Router } from '@angular/router';
import { AdminService } from './../../../services/admin.service';

declare var jQuery:any;
declare var $:any;
declare var iziToast;


import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { error } from 'console';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',  
})
export class LoginComponent implements OnInit {

 
  public token : any = '';

  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  public active = 1;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _adminService:AdminService,
    private _router: Router
    ) {
    this.createLoginForm();
    this.createRegisterForm();
    this.token = this._adminService.getToken();
  }

  owlcarousel = [
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  createLoginForm() {    
    this.loginForm = this.formBuilder.group({
      email: ['', [ Validators.required, Validators.minLength(3) ] ],
      password: ['', [ Validators.required, Validators.minLength(3) ] ],
    })
  }
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      email: [''],
      password: [''],
      confirmPassword: [''],
    })
  }


  ngOnInit() {
    if(this.token){
      this._router.navigate(['/']);
    }else{
      //MANTENER EN EL COMPONENTE
    }
  }

  onSubmit() {
    if(this.loginForm.valid){
      
      let data = {
        email: this.loginForm.get('email')?.value,
        password: this.loginForm.get('password')?.value
      }
      
      this._adminService.login_admin(data).subscribe({
        next: (response) => {
          if(response.data == undefined){
            iziToast.show({
              title: 'ERROR',
              titleColor: '#FF0000',
              color: '#FFF',
              class: 'text-danger',
              position: 'topRight',
              message: response.message,
      
            });
          }else{

            localStorage.setItem('token',response.token);
            localStorage.setItem('_id',response.data._id);

            this._router.navigate(['/']);
          }
        },
        error: (error) => {
          console.log(error);

        }
      })
    }
  }
}
