import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-seccion',
  templateUrl: './create-seccion.component.html',
  //styleUrls: ['./create-seccion.component.scss']
})
export class CreateSeccionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
