import { Component, DEFAULT_CURRENCY_CODE, OnInit } from '@angular/core';
import { GLOBAL } from "src/app/services/GLOBAL";
import { BannerService } from 'src/app/services/banner.service';
import { Router } from '@angular/router';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var iziToast;
declare var $:any;

@Component({
  selector: 'app-index-banner',
  templateUrl: './index-banner.component.html',
  //styleUrls: ['./index-banner.component.css']
})
export class IndexBannerComponent implements OnInit {

  public closeResult: string;
  public clientes : Array<any>=[];

  public load_data = true;
  public filtro = '';
  public token;
  public banners : Array<any> = [];  
  public url;
  public page = 1;
  public pageSize = 20;
  public itemId;

  public load_btn = false;

  constructor(
    private _bannerService : BannerService,
    private modalService: NgbModal,
    private _router: Router
  ) {
    this.token = localStorage.getItem('token');
    this.url = GLOBAL.url;    
  }

  ngOnInit(): void {    
    this.init_data();
  }

  init_data(){
    this._bannerService.listar_banners_admin(this.filtro, this.token).subscribe(
      response=>{        
        this.banners = response.data;
        console.log(this.banners);
        this.load_data = false;       
      }     
    )
  }

  openModal(modal, itemId: string) {
    this.itemId = itemId;
    this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed ${this.getDismissReason(reason)}`);
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  desactivar(id){    
    this._bannerService.desactivar_banner_admin(id,this.token).subscribe({
      next:(response)=>{
        //iziToast.show({title: 'SUCCESS',titleColor: '#1DC74C',color: '#FFF',class: 'text-success',position: 'topRight',message: 'Se activó correctamente el banner.'});        
        this._bannerService.listar_banners_admin(this.filtro, this.token).subscribe(
          response=>{        
            this.banners = response.data;        
            this.modalService.dismissAll('Cross click');            
          }     
        )                       
      },
      error:(error)=>{
        iziToast.show({title: 'ERROR',titleColor: '#FF0000',color: '#FFF',class: 'text-danger',position: 'topRight',message: 'Ocurrió un error en el servidor.'});                
      }
  })
  }

  activar(id){    
    this._bannerService.activar_banner_admin(id,this.token).subscribe({
      next:(response)=>{
        //iziToast.show({title: 'SUCCESS',titleColor: '#1DC74C',color: '#FFF',class: 'text-success',position: 'topRight',message: 'Se activó correctamente el banner.'});        
        this._bannerService.listar_banners_admin(this.filtro, this.token).subscribe(
          response=>{        
            this.banners = response.data;        
            this.modalService.dismissAll('Cross click');            
          }     
        )                       
      },
      error:(error)=>{
        iziToast.show({title: 'ERROR',titleColor: '#FF0000',color: '#FFF',class: 'text-danger',position: 'topRight',message: 'Ocurrió un error en el servidor.'});                
      }
  })
  }

}
