import { Component, DEFAULT_CURRENCY_CODE, OnInit, QueryList, ViewChild, ViewChildren  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductoService } from 'src/app/services/producto.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';

import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { TableService } from 'src/app/shared/service/table.service';
import { UserListDB, USERLISTDB } from 'src/app/shared/tables/list-users';

declare var iziToast;
declare var $:any;
declare var iziToast;

@Component({
  selector: 'app-inventario-producto',
  templateUrl: './inventario-producto.component.html',  
  providers: [TableService, DecimalPipe]
})
export class InventarioProductoComponent implements OnInit {

  public user_list = []

  public tableItem$: Observable<UserListDB[]>;
  public searchText;
  total$: Observable<number>;

  @ViewChild('closebutton') closebutton;

  public closeResult: string;

  public id;
  public token;
  public _iduser;
  public producto :any = {};
  public inventarios : Array<any>=[];
  public arr_inventario : Array<any>=[];  
  public inventario : any = {};

  public page = 1;
  public pageSize = 20;

  public load_btn = false;

  constructor(
    private _route: ActivatedRoute,
    private _productoService: ProductoService,
    private modalService: NgbModal,
    public service: TableService
  ) {
    this.token = localStorage.getItem('token');
    this._iduser = localStorage.getItem('_id');
    console.log(this._iduser);

    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(USERLISTDB)
    
   }

   @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

   onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnInit(): void {
    this._route.params.subscribe(
      params=>{
        this.id = params['id'];
        
        this._productoService.obtener_producto_admin(this.id,this.token).subscribe(
          response=>{
            if(response == undefined){
              this.producto = undefined;
            }else{
              this.producto = response.data;  
              

              this._productoService.listar_inventario_producto_admin(this.producto._id, this.token).subscribe(
                response=>{                  
                  this.inventarios = response.data;
                  this.inventarios.forEach(element => {
                    this.arr_inventario.push({
                      admin: element.admin.nombres + ' ' + element.admin.apellidos,
                      cantidad: element.cantidad,
                      proveedor: element.proveedor,
                    });
                  });
                },
                error=>{
                  
                }
              )
                          
            }
            
          }
        ),
        error=>{
          
          
        }
        
      }
    );
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public onSave() {
    this.closebutton.nativeElement.click();
  }

  eliminar(id){
    this.load_btn = true;
    this._productoService.eliminar_inventario_producto_admin(id,this.token).subscribe({
      next: (response)=>{
        iziToast.show({
            title: 'SUCCESS',
            titleColor: '#1DC74C',
            color: '#FFF',
            class: 'text-success',
            position: 'topRight',
            message: 'Se eliminó correctamente el nuevo producto.',        
        });
        this.modalService.dismissAll();
        this.load_btn = false;

        this._productoService.listar_inventario_producto_admin(this.producto._id, this.token).subscribe({
          next: (response)=>{ this.inventarios = response.data },
          error: (error)=>{ console.log(error) }
        })        
      },
      error: (error)=>{
        iziToast.show({
            title: 'ERROR',
            titleColor: '#FF0000',
            color: '#FFF',
            class: 'text-danger',
            position: 'topRight',
            message: 'Ocurrió un error en el servidor.',        
        });
        console.log(error);
        this.load_btn = false;        
      }
    })
  }

  registro_inventario(inventarioForm){
    if( inventarioForm.valid) {    
      
      let data = {
        producto: this.producto._id,
        cantidad: inventarioForm.value.cantidad,
        admin: this._iduser,
        proveedor: inventarioForm.value.proveedor,
      }

      this._productoService.registro_inventario_producto_admin(data,this.token).subscribe({
        next: (response)=>{
          iziToast.show({
              title: 'SUCCESS',
              titleColor: '#1DC74C',
              color: '#FFF',
              class: 'text-success',
              position: 'topRight',
              message: 'Se agrego el nuevo stock al producto.',            
          });
          this._productoService.listar_inventario_producto_admin(this.producto._id, this.token).subscribe({
            next: (response)=>{ this.inventarios = response.data },            
          })          
        },
        error:(error)=>{ console.log(error)}
      })      
    }else{
      iziToast.show({
          title: 'ERROR',
          titleColor: '#FF0000',
          color: '#FFF',
          class: 'text-danger',
          position: 'topRight',
          message: 'Los datos del formulario no son validos',        
      });

    }
  }

  download_excel(){
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Reporte de productos");

    worksheet.addRow(undefined);
    for (let x1 of this.arr_inventario){
      let x2 = Object.keys(x1);

      let temp=[];
      for(let y of x2){
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    let fname='REP01- ';

    worksheet.columns = [
      { header: 'Trabajador', key: 'col1', width: 30},
      { header: 'Cantidad', key: 'col2', width: 15},
      { header: 'Proveedor', key: 'col3', width: 25},      
    ]as any;

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      fs.saveAs(blob, fname+'-'+new Date().valueOf()+'.xlsx');
    })
  }

}
