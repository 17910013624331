<div class="card box-shadow-sm">
    <div class="card-header">
        <h5 style="margin-bottom: 0px;">Filtro de productos</h5>
    </div>
    <div class="card-header">
        <div class="row">
            <div class="col-6">
                <div class="input-group">
                    <input class="form-control" type="text" placeholder="Título del producto" name="filtro" [(ngModel)]="filtro">
                    <button class="btn btn-primary" type="button" (click)="filtrar()">Filtrar</button>
                    <button class="btn btn-info" type="button" (click)="resetear()">Resetear</button>
                </div>
            </div>
            <div class="col-12 mt-3">
              <button class="btn btn-success" (click)="download_excel()">Exportar a excel</button>
            </div>
        </div>
    </div>   
    <div class="card-body vendor-table">
      <div class="custom-datatable vendor-list">
          <div class="table-responsive ">            
              <table class="table table-striped">
                  <thead>
                      <tr>
                          <th scope="col">Título</th>
                          <th scope="col">Stock</th>
                          <th scope="col">Precio</th>
                          <th scope="col">Categoría</th>                              
                          <th scope="col">Ventas</th>
                          <th scope="col">OP</th>
                      </tr>
                  </thead>
                  <tbody *ngIf="load_data">
                    <tr>
                        <td scope="row">
                          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                            <span class="sr-only">Loading...</span>
                          </div> 
                        </td>                            
                    </tr>
                </tbody>
                  <tbody *ngIf="!load_data">
                    <tr *ngFor="let item of productos| slice: (page-1)*pageSize : (page-1) * pageSize + pageSize">                                             
                          <td>
                              <h6 style="padding-bottom: 0;margin-bottom: 0;">
                                  <img style="width: 50px;" [src]="url+'obtener_portada/'+item.portada" class="img-thumbnail rounded-0" alt="Square image">
                                  {{item.titulo}}

                              </h6>
                          </td>
                          <td>{{item.stock}}</td>
                          <td>{{item.precio}}</td>
                          <td>{{item.categoria}}</td>
                          <td>{{item.nventas}}</td>
                          <td>
                            

                            <div class="btn-group dropdown d-inline-block mb-3 me-2">
                                <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">OPCIONES</button>
                                <div class="dropdown-menu">
                                  <a class="dropdown-item" [routerLink]="['/productos/update-producto',item._id]" >Editar</a>
                                  <a 
                                    class="dropdown-item" style="cursor: pointer;" data-toggle="modal" data-original-title="test" 
                                    data-target="#exampleModal" (click)="open(content)" [attr.data-target]="'#delete-'+item._id">Eliminar
                                  </a>                                  
                                  <a class="dropdown-item" [routerLink]="['/productos/inventario-producto',item._id]" >Inventario</a>
                                  <a class="dropdown-item" [routerLink]="['/productos/variedad-producto',item._id]" >Variedades</a>
                                  <a class="dropdown-item" [routerLink]="['/productos/galeria-producto',item._id]" >Galeria</a>
                                  <a class="dropdown-item" [routerLink]="['/productos/reviews-producto',item._id]" >Reseñas</a>
                                  
                                </div>
                            </div>
                                   
                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <h5 class="modal-title f-w-600" id="exampleModalLabel">Eliminar producto</h5>
                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
                                </div>
                                <div class="modal-body">                                          
                                  <p><strong>¿Desea eliminar el producto <span class="text-primary">{{item.titulo}}?</span></strong></p>
                                  <p>Toda la información asociada a este producto va a ser eliminada por completo.
                                  <span class="text-danger">Esta operación no se puede deshacer.</span>
                                  </p>
                                </div>
                                <div class="modal-footer">
                                  <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancelar</button>
                                  <button *ngIf="!load_btn" class="btn btn-primary btn-shadow btn-sm" (click)="eliminar(item._id)" type="button">Si, eliminar</button>
                                  
                                  <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                                    <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                    Eliminando...
                                  </button>
                                </div>
                            </ng-template>
                        </td>
                    </tr>
                  </tbody>
              </table>
              <div class="d-flex justify-content-center p-2">
                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="productos.length">
                </ngb-pagination>
             </div>
          </div>
      </div>
  </div>    
</div>    
