import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientesRoutingModule } from './clientes-routing.module';
 import { IndexClienteComponent } from './index-cliente/index-cliente.component';
// import { CreateVendorsComponent } from './create-vendors/create-vendors.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateClienteComponent } from './create-cliente/create-cliente.component';
import { EditClienteComponent } from './edit-cliente/edit-cliente.component';
// import { Ng2SmartTableModule } from 'ng2-smart-table';

import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    IndexClienteComponent,
    CreateClienteComponent,
    EditClienteComponent
      //, CreateVendorsComponent
],
  imports: [
    CommonModule,
    ClientesRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    // Ng2SmartTableModule    
    FormsModule,
    Ng2SearchPipeModule,
    SharedModule,
    
  ]
})
export class ClientesModule { }