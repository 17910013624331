<form #registroForm="ngForm" (ngSubmit)="registro(registroForm)" >
    <div class="card box-shadow-sm">            
            <div class="card-body">            
                <div class="row">
                    <div class="col-md-4 form-group">
                        <label for="validationCustom01" class="form-label">Nombres</label>
                        <input class="form-control" type="text" placeholder="Nombres completos" required name="nombres" [(ngModel)]="cliente.nombres">
                    </div>
                        <div class="col-md-4 form-group">
                        <label for="validationCustom01" class="form-label">Apellidos</label>
                        <input class="form-control" type="text" placeholder="Apellidos completos" required name="apellidos" [(ngModel)]="cliente.apellidos">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="validationCustom01" class="form-label">Correo electrónico</label>
                        <input class="form-control" type="email" placeholder="Correo electrónico" required name="email" [(ngModel)]="cliente.email">
                    </div>

                    <div class="col-md-4 form-group">
                        <label for="validationCustom01" class="form-label">Teléfono</label>
                        <input class="form-control" type="text" placeholder="Teléfono" required name="telefono" [(ngModel)]="cliente.telefono">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="validationCustom01" class="form-label">Fecha nacimiento</label>
                        <input class="form-control" type="date" required name="f_nacimiento" [(ngModel)]="cliente.f_nacimiento">
                    </div>
                    <div class="col-md-4 form-group">
                        <label for="validationCustom01" class="form-label">DNI</label>
                        <input class="form-control" type="number" placeholder="DNI" required name="dni" [(ngModel)]="cliente.dni">
                    </div>

                    <div class="col-md-4">
                        <label for="validationCustom01" class="form-label">Genero</label>
                        <select class="form-control custom-select" name="genero" required [(ngModel)]="cliente.genero">
                            <option value="" disabled selected>Seleccionar</option>
                            <option value="Masculino">Masculino</option>
                            <option value="Femenino">Femenino</option>
                        </select>
                    </div>
                </div>            
            </div>
            <div class="card-footer">
                <button *ngIf="!load_btn" class="btn btn-secondary mr-2" type="submit">Crear cliente</button>
                <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                    <span class="spinner-grow spinner-grow-sm " role="status" aria-hidden="true"></span><button *ngIf="!load_btn" class="btn btn-secondary" type="submit">Actualizar cliente</button>
                    <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                        <span class="spinner-grow spinner-grow-sm " role="status" aria-hidden="true"></span>
                        Actualizando...
                    </button>
                    <button [routerLink]="['/clientes/index-cliente']" class="btn btn-primary" type="button">Regresar</button>                 
                    Creando...
                </button>
                <button [routerLink]="['/clientes/index-cliente']" class="btn btn-primary" type="button">Regresar</button>                 
            </div>
    </div> 
</form>