import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var jQuery:any;
declare var $:any;
declare var iziToast;

@Component({
  selector: 'app-index-cliente',
  templateUrl: './index-cliente.component.html'
  //styleUrls: ['./index-cliente.component.css']
})
export class IndexClienteComponent implements OnInit {

  public closeResult: string;
  public clientes : Array<any>=[];
  public filtro_apellidos = '';
  public filtro_correo = '';

  public page = 1;
  public pageSize = 20;
  public token;
  public load_data = true;

  constructor(
    private _clienteService : ClienteService,
    private _adminService : AdminService,
    private modalService: NgbModal
  ) {
    this.token = this._adminService.getToken();   
    
   }

  ngOnInit(): void {
    this.init_Data()          ;
  }

  init_Data(){
    this._clienteService.listar_clientes_filtro_admin(null, null, this.token).subscribe({
      next: (response) => {
        this.clientes = response.data;
        this.load_data = false;
      },
      error: (error) => {
        console.log(error);
      }
    });    
  }

  filtro(tipo){
    
    if(tipo == 'apellidos'){
      this.load_data = true;
      if(this.filtro_apellidos){
        this._clienteService.listar_clientes_filtro_admin(tipo,this.filtro_apellidos,this.token).subscribe({
          next: (response)=>{
            this.clientes = response.data;
            this.load_data = false;        
          },
          error:(error)=>{
            console.log(error);        
          }
        });
      }else{
        this.init_Data();
      }
       
    }else if(tipo == 'correo'){
      this.load_data = true;
      if(this.filtro_correo){
        this._clienteService.listar_clientes_filtro_admin(tipo,this.filtro_correo,this.token).subscribe({

          next:(response)=>{
            this.clientes = response.data;
            this.load_data = false;        
          },
          error:(error)=>{
            console.log(error);        
          }
        }); 
      }else{
        this.init_Data();
      }
      
    } 
    
    
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  eliminar(id){
    this._clienteService.eliminar_cliente_admin(id,this.token).subscribe({
      next:(response)=>{
        iziToast.show({
          title: 'SUCCESS',
          titleColor: '#1DC74C',
          color: '#FFF',
          class: 'text-success',
          position: 'topRight',
          message: 'Se eliminó correctamente el nuevo cliente.',        
      });

      // $('#delete-'+id).modal('hide');
      // $('.modal-backdrop').removeClass('show');

      this.modalService.dismissAll('Cross click');

      this.init_Data();
        
      },
      error:(error)=>{
        console.log(error);
        
      }
    })
  }

}
