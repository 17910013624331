<div class="card">     
  <div class="card-body">
    <div class="category-table custom-datatable">
        <div class="table-responsive ">            
            <table class="table table-striped">
                <thead>
                    <tr>
                      <th>Imágen</th>
                      <th>Título</th>
                      <th>Posición</th>                      
                      <th>Estado</th>                      
                      <th>OP</th>
                    </tr>
                </thead>
                <tbody *ngIf="load_data">
                  <tr>
                      <td scope="row">
                        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                          <span class="sr-only">Loading...</span>
                        </div> 
                      </td>                            
                  </tr>
              </tbody>
              <ng-container *ngIf="!load_data">
                <ng-container *ngIf="banners.length == 0" >
                  <tr>
                      <td colspan="5" class="text-center">
                          <span class="text-muted">No se encontraron registros</span>
                      </td>
                  </tr>
                </ng-container >
                <ng-container *ngIf="banners.length >= 1">
                  <tbody *ngFor="let item of banners| slice: (page-1)*pageSize : (page-1) * pageSize + pageSize">                       
                      <tr>
                        <td>
                          <h6 style="padding-bottom: 0;margin-bottom: 0;">                            
                            <img [src]="url+'obtener_banner_banner/'+item.banner" class="imgTable" style="width: 100px; height: auto;">
                          </h6>
                        </td>
                        <td>
                            <h6 style="padding-bottom: 0;margin-bottom: 0;">                                
                                {{item.titulo}}

                            </h6>
                        </td>
                        <td>{{item.posicion}}</td>                                
                        <td><span class='badge {{item.order_class}}'>{{ item.status }}</span></td>
                        <td>
                          <div class="btn-group dropdown d-inline-block mb-3 me-2">
                            <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">OPCIONES</button>
                            <div class="dropdown-menu">
                              <a class="dropdown-item" [routerLink]="['/banners/edit-banner', item._id]">Editar Banner</a>
                              <ng-container *ngIf="item.status === 'activado'">
                                <a style="cursor: pointer;" class="dropdown-item" (click)="openModal(modalDesactivar, item._id)">Desactivar</a>
                              </ng-container>
                              <ng-container *ngIf="item.status === 'desactivado'">
                                <a style="cursor: pointer;" class="dropdown-item" (click)="openModal(modalActivar, item._id)">Activar</a>
                              </ng-container>
                            </div>
                          </div>
                        
                          <!-- Modal Desactivar -->
                          <ng-template #modalDesactivar let-modal>
                            <div class="modal-header">
                              <h5 class="modal-title f-w-600">Desactivar</h5>
                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p class="fs-sm">Desea desactivar este banner?</p>                              
                            </div>
                            <div class="modal-footer">
                              <button type="button" (click)="desactivar(itemId)" class="btn btn-primary">Desactivar</button>
                              <button class="btn btn-secondary" type="button" (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                          </ng-template>
                        
                          <!-- Modal Activar -->
                          <ng-template #modalActivar let-modal>
                            <div class="modal-header">
                              <h5 class="modal-title f-w-600">Activar</h5>
                              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p class="fs-sm">Desea activar este banner?</p>                              
                            </div>
                            <div class="modal-footer">
                              <button type="button" (click)="activar(itemId)" class="btn btn-primary">Activar</button>
                              <button class="btn btn-secondary" type="button" (click)="modal.dismiss('Cross click')">Close</button>
                            </div>
                          </ng-template>
                        </td>
                        
                      </tr>
                  </tbody>
                </ng-container>
              
            </ng-container>
            </table>            
        </div>
    </div>
</div>    
</div>    
