import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { GLOBAL } from "./GLOBAL";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  public url;

  constructor(
    private _http: HttpClient,
  ) {
    this.url = GLOBAL.url;
  }

  registro_producto_admin(data,file,token):Observable<any>{
    let headers = new HttpHeaders({'Authorization':token});

    const tipo = "tienda";

    const fd = new FormData();
    fd.append('titulo',data.titulo);
    fd.append('stock',data.stock);
    fd.append('precio',data.precio);
    fd.append('descripcion',data.descripcion);
    fd.append('contenido',data.contenido);
    fd.append('categoria',data.categoria);
    fd.append('portada',file);    
    fd.append('tipo',tipo);    

    return this._http.post(this.url+'registro_producto_admin/',fd,{headers:headers});

  }

  registro_seccion_admin(data,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.post(this.url+'registro_seccion_admin/',data,{headers:headers});

  }

  registro_programa_admin(data,file,token):Observable<any>{
    let headers = new HttpHeaders({'Authorization':token});

    const tipo = "programa";

    const fd = new FormData();
    fd.append('titulo',data.titulo);    
    fd.append('precio',data.precio);
    fd.append('vencimiento',data.vencimiento);
    fd.append('descripcion',data.descripcion);
    fd.append('contenido',data.contenido);    
    fd.append('portada',file);    
    fd.append('tipo',tipo);    

    return this._http.post(this.url+'registro_programa_admin/',fd,{headers:headers});

  }

  listar_productos_admin(filtro,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.get(this.url+'listar_productos_admin/'+filtro,{headers:headers});

  }

  listar_programas_admin(filtro,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.get(this.url+'listar_programas_admin/'+filtro,{headers:headers});

  } 

  obtener_producto_admin(id,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.get(this.url+'obtener_producto_admin/'+id,{headers:headers});

  }

  obtener_secciones_programa_admin(id,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.get(this.url+'obtener_secciones_programa_admin/'+id,{headers:headers});

  }

  actualizar_producto_admin(data,id,token):Observable<any>{
    if(data.portada){
      let headers = new HttpHeaders({'Authorization':token});

      const fd = new FormData();
      fd.append('titulo',data.titulo);
      fd.append('stock',data.stock);
      fd.append('precio',data.precio);
      fd.append('descripcion',data.descripcion);
      fd.append('contenido',data.contenido);
      fd.append('categoria',data.categoria);
      fd.append('portada',data.portada);          

      return this._http.put(this.url+'actualizar_producto_admin/'+id,fd,{headers:headers});
    }else{
      let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
      return this._http.put(this.url+'actualizar_producto_admin/'+id,data,{headers:headers});
    }

  }

  actualizar_programa_admin(data,id,token):Observable<any>{
    if(data.portada){
      let headers = new HttpHeaders({'Authorization':token});

      const fd = new FormData();
      fd.append('titulo',data.titulo);
      fd.append('stock',data.stock);
      fd.append('precio',data.precio);
      fd.append('descripcion',data.descripcion);
      fd.append('contenido',data.contenido);
      fd.append('categoria',data.categoria);
      fd.append('portada',data.portada); 
      fd.append('vencimiento',data.vencimiento);       

      return this._http.put(this.url+'actualizar_programa_admin/'+id,fd,{headers:headers});
    }else{
      let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
      return this._http.put(this.url+'actualizar_programa_admin/'+id,data,{headers:headers});
    }

  }

  eliminar_producto_admin(id,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.delete(this.url+'eliminar_producto_admin/'+id,{headers:headers});
  } 

  listar_inventario_producto_admin(id,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.get(this.url+'listar_inventario_producto_admin/'+id,{headers:headers});

  } 
  
  eliminar_inventario_producto_admin(id,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.delete(this.url+'eliminar_inventario_producto_admin/'+id,{headers:headers});

  } 

  
  registro_inventario_producto_admin(data,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.post(this.url+'registro_inventario_producto_admin',data,{headers:headers});

  } 

  actualizar_producto_variedades_admin(data,id,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.put(this.url+'actualizar_producto_variedades_admin/'+id,data,{headers:headers});
  }

  agregar_imagen_galeria_admin(id,data,token):Observable<any>{
    let headers = new HttpHeaders({'Authorization':token});

    const fd = new FormData();
    fd.append('_id',data._id);   
    fd.append('imagen',data.imagen);   

    return this._http.put(this.url+'agregar_imagen_galeria_admin/'+id,fd,{headers:headers});

  }

  agregar_seccion_admin(id,data,token):Observable<any>{
    let headers = new HttpHeaders({'Authorization':token});

    const fd = new FormData();
    fd.append('_id',data._id);   
    fd.append('titulo',data.titulo);   
    fd.append('descripcion',data.descripcion);   
    fd.append('posicion',data.posicion);   
    fd.append('videos',data.videos); 

    return this._http.put(this.url+'agregar_seccion_admin/'+id,fd,{headers:headers});
  }

  agregar_video_seccion_admin(id,data,token):Observable<any>{
    let headers = new HttpHeaders({'Authorization':token});

    const fd = new FormData();
    fd.append('_id',data._id);   
    fd.append('video',data.video);   
    //fd.append('preview',data.preview);
    fd.append('free',data.free);   
    fd.append('titulo',data.titulo);   
    fd.append('posicion',data.posicion);   

    return this._http.put(this.url+'agregar_video_seccion_admin/'+id,fd,{headers:headers});
  }
  
  eliminar_imagen_galeria_admin(id,data,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.put(this.url+'eliminar_imagen_galeria_admin/'+id,data,{headers:headers});
  }
  
  eliminar_seccion_programa_admin(id,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.delete(this.url+'eliminar_seccion_programa_admin/'+id,{headers:headers});
  }

  actualizar_seccion_programa_admin(id,data,token):Observable<any>{
    if(data.portada){
      let headers = new HttpHeaders({'Authorization':token});

      const fd = new FormData();
      fd.append('titulo',data.titulo);
      fd.append('posicion',data.posicion);      
      fd.append('descripcion',data.descripcion);            

      return this._http.put(this.url+'actualizar_seccion_programa_admin/'+id,fd,{headers:headers});
    }else{
      let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
      return this._http.put(this.url+'actualizar_seccion_programa_admin/'+id,data,{headers:headers});
    }

  }
  
  eliminar_video_seccion_programa_admin(id,data,token):Observable<any>{
    let headers = new HttpHeaders({'Content-Type':'application/json','Authorization':token});
    return this._http.put(this.url+'eliminar_video_seccion_programa_admin/'+id,data,{headers:headers});
  }
  
  
  obtener_reviews_producto_publico(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url+'obtener_reviews_producto_publico/'+id,{headers:headers});

  }
}
