<div class="card box-shadow-sm">
    <div class="card-body">
        <div class="row mb-5">
            <div class="col-md-4 col-sm-12 mb-3 mb-md-0">
                <img [src]="url+'obtener_portada/'+producto.portada" class="img-thumbnail rounded-0" alt="Square image">
            </div>
            <div class="col-md-8 col-sm-12">
                <div class="card" style="height: 100% !important; background: #f5eeee;">
                    <div class="card-body">
                        <h3 class="card-product-title text-truncate mb-2">
                            <a class="nav-link mb-3" >{{producto.titulo}}</a>
                            <p class="text-muted">{{producto.descripcion}}</p>
                            <div class="d-flex align-items-center">
                                <span class="h5 d-inline-block mb-0 text-danger">${{producto.precio}}</span>                                    
                            </div>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <form #registroForm="ngForm" (ngSubmit)="registro(registroForm)">
                <div class="card box-shadow-sm">
                    <div class="card-header">
                        <h5 style="margin-bottom: 0px;">Agregar sección</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-8 form-group">
                                <label for="">Título de la sección</label>
                                <input type="text" class="form-control" placeholder="Título de la sección" required name="titulo" [(ngModel)]="seccion.titulo">
                            </div>
                            <div class="col-lg-4 form-group">
                                <label for="">Orden de posición</label>
                                <input type="number" class="form-control" placeholder="Orden de posición" required name="posicion" [(ngModel)]="seccion.posicion">
                            </div>
                            <div class="col-lg-12 form-group">
                                <label for="">Descripción corta</label>
                                <textarea class="form-control" placeholder="Descripción corta" required rows="5" name="descripcion" [(ngModel)]="seccion.descripcion"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button *ngIf="!load_btn" class="btn btn-primary mr-3" type="submit">Agregar</button>
                        <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                            <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                            Creando...
                        </button>
                    </div>
                </div>
            </form>
            
        </div>
        <div class="custom-datatable">
            <div class="table-responsive">
                    <table class="table table-striped" *ngIf="secciones">
                        <thead>
                            <tr>                
                                <th>Orden</th>
                                <th>Seccion</th>
                                <th>Videos</th>
                            </tr>
                        </thead>
                    
                        <tbody *ngIf="secciones.length == 0">
                            <tr>
                                <td colspan="2" class="text-center"> <span class="text-muted">No hay secciones disponibles</span> </td>
                            </tr>
                        </tbody>
                    
                        <tbody *ngIf="secciones.length>=1">
                            <tr *ngFor="let item of secciones; let indice = index">
                                <td>{{item.posicion}}  - 
                                    <!-- <a href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                    <a href="javascript:void(0)"><i class="fa fa-trash-o"></i></a> -->
                                    <!-- ------------------------------------Eliminar seccion-------------------------- -->
                                    <a 
                                        style="cursor: pointer;" data-toggle="modal" data-target="#deleteModal" (click)="open(delete)" 
                                        [attr.data-target]="'#delete_seccion-'+item._id"><i class="fa fa-trash-o"></i>
                                    </a> -
                                    <ng-template #delete let-modal>
                                        <div class="modal-header">
                                            <h5 class="modal-title f-w-600" id="deleteModalLabel">Confirmación de eliminación</h5>
                                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
                                        </div>
                                        <div class="modal-body">
                                            <p><strong>Desea eliminar esta sección: <span class="text-primary">{{item.titulo}}?</span></strong></p>
                                            <p>Toda la información asociada a esta sección va a ser eliminada por completo.
                                                <span class="text-danger">Esta operación no se puede deshacer.</span>
                                            </p>
                                        </div>
                                        <div class="modal-footer">
                                            <div class="d-flex justify-content-between">
                                                <button class="btn btn-secondary mr-3" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancelar</button>
                                                <button *ngIf="!load_btn" class="btn btn-primary btn-shadow btn-sm" (click)="eliminar_seccion(item._id)" type="button">Si, eliminar</button>
                                                <button *ngIf="load_btn" type="button" class="btn btn-secondary" disabled>
                                                    <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                    Eliminando...
                                                </button>
                                            </div>
                                        </div>
                                    </ng-template>
                                    
                                            
                                    <!-- ------------------------------------Editar seccion-------------------------- -->                                            
                                    <a 
                                        style="cursor: pointer;" data-toggle="modal" data-target="#updateModal" (click)="open(update,item)" 
                                        [attr.data-target]="'#update_seccion-'+item._id"><i class='fa fa-edit f-12'></i>
                                    </a> - 
                                            <ng-template #update let-modal>
                                                <div class="modal-header">
                                                    <h5 class="modal-title f-w-600" id="updateModalLabel">Actualizar sección</h5>
                                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="form-group">
                                                        <label for="">Título de la sección</label>
                                                        <input type="text" class="form-control" required name="titulo" [(ngModel)]="item2.titulo">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="">Orden de posición del video</label>
                                                        <input type="number" class="form-control" required name="posicion" [(ngModel)]="item2.posicion">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="">Descripción</label>
                                                        <textarea class="form-control" required rows="5" name="descripcion" [(ngModel)]="item2.descripcion"></textarea>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <div class="d-flex justify-content-between">
                                                        <button class="btn btn-primary mr-3" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancelar</button>
                                                        <button *ngIf="!load_btn_actualizar_seccion" class="btn btn-secondary btn-shadow btn-sm" (click)="actualizar_seccion(item._id)" type="button">Actualizar</button>
                                                        <button *ngIf="load_btn_actualizar_seccion" type="button" class="btn btn-primary" disabled>
                                                            <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                            Actualizando...
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                    
                                   
                                </td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span>                                        
                                            {{item.titulo}}
                                            <!-- ------------------------------------Subir video-------------------------- -->
                                            <a 
                                                style="cursor: pointer;" data-toggle="modal" data-target="#uploadModal" (click)="open(upload,item)" 
                                                [attr.data-target]="'#subir-'+item._id"><i class="fa fa-upload f-12"></i>
                                            </a> -
    
                                                    <ng-template #upload let-modal>
                                                        <div class="modal-header">
                                                            <h5 class="modal-title f-w-600" id="uploadModalLabel">Subir video</h5>
                                                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="form-group">
                                                                <label for="">Título del video</label>
                                                                <input type="text" class="form-control" placeholder="Titulo del video" required name="titulo" [(ngModel)]="video.titulo">
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="">Orden de posición del video</label>
                                                                <input type="number" class="form-control" placeholder="Orden de posición del video" required name="posicion" [(ngModel)]="video.posicion">
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="">Subir video</label>
                                                                <input id="input-video" class="form-control" (change)="fileChangeEvent($event)" type="file">
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <div class="d-flex justify-content-between">
                                                                <button class="btn btn-primary mr-3" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancelar</button>
                                                                <button *ngIf="!load_btn_subir_video" class="btn btn-secondary btn-shadow btn-sm" type="button" (click)="subir_video(item._id)">Subir</button>
                                                                <button *ngIf="load_btn_subir_video" type="button" class="btn btn-primary" disabled>
                                                                    <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                                    Subiendo...
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                                                 
                                        </span>
                                    </div>
                                    
                                    
                                  
                                </td>
                                <td>
                                    <ng-container *ngFor="let video of item.videos">
                                        <table class="table table-bordered">
                                            <tr>
                                                <td>
                                                    <span style="color:red; font-weight:bold">{{video.posicion}}</span>
                                                </td>
                                                <td>{{video.titulo}}</td>
                                                <td>
                                                    <!-- ------------------------------------Eliminar Video -------------------------- -->
                                                    <a 
                                                        style="cursor: pointer;" data-toggle="modal" data-target="#deleteModal" (click)="open(delete_video)" 
                                                        [attr.data-target]="'#delete_video-'+video._id"><i class="fa fa-trash-o"></i>
                                                    </a> -
                                                            <ng-template #delete_video let-modal>
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title f-w-600" id="deleteModalLabel">Confirmación de eliminación</h5>
                                                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <p><strong>Desea eliminar este video: <span class="text-primary">{{video.titulo}}  {{video._id}}?</span></strong></p>
                                                                    <p>Toda la información asociada a este video va a ser eliminada por completo. <span class="text-danger">Esta operación no se puede deshacer.</span></p>
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <div class="d-flex justify-content-between">
                                                                        <button class="btn btn-secondary mr-3" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Cancelar</button>
                                                                        <button *ngIf="!load_btn_eliminar_video" class="btn btn-primary btn-shadow btn-sm" (click)="eliminar_video(item._id,video)" type="button">Si, eliminar</button>
                                                                        <button *ngIf="load_btn_eliminar_video" type="button" class="btn btn-secondary" disabled>
                                                                            <span class="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                                            Eliminando...
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                    <!-- ------------------------------------Ver Video -------------------------- -->
                                                    <a 
                                                        style="cursor: pointer;" data-toggle="modal" data-target="#viewModal" (click)="open(view_video)" 
                                                        [attr.data-target]="'#view-'+video._id"><i class="fa fa-eye f-12"></i>
                                                    </a> -
                                                            <ng-template #view_video let-modal>
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title f-w-600" id="viewModalLabel">Video</h5>
                                                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span> </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="embed-responsive embed-responsive-16by9">
                                                                        <video #videop class="embed-responsive-item" [src]="url+'obtener_portada/'+video.video" controls controlsList="nodownload"></video>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                    <ng-container *ngIf="video.free == 'true'">
                                                        <b><a style="cursor: pointer;color:green; font-weight:bold"  (click)="change_free_video(item._id,video,false)" > FREE</a></b>                                                
                                                    </ng-container>
                                                    <ng-container *ngIf="video.free == 'false'">
                                                        <b><del><a style="cursor: pointer;color:red; font-weight:bold"  (click)="change_free_video(item._id,video,true)" > NO FREE</a></del></b>                                                
                                                    </ng-container> 
                                                </td>
                                            </tr>
                                        </table>
                                    </ng-container>
                                </td>
                            </tr> 
                        </tbody>
                    </table>
            </div>
        </div>        
    </div> 
</div> 