import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductosRoutingModule } from './productos-routing.module';
 import { IndexProductoComponent } from './index-producto/index-producto.component';
// import { CreateVendorsComponent } from './create-vendors/create-vendors.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SharedModule } from 'src/app/shared/shared.module';
import { UpdateProductoComponent } from './update-producto/update-producto.component';

import { NgxTinymceModule } from 'ngx-tinymce';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InventarioProductoComponent } from './inventario-producto/inventario-producto.component';
import { VariedadProductoComponent } from './variedad-producto/variedad-producto.component';
import { GaleriaProductoComponent } from './galeria-producto/galeria-producto.component';
import { CreateProductoComponent } from './create-producto/create-producto.component';
import { ReviewsProductoComponent } from './reviews-producto/reviews-producto.component';
import { CreateProgramaComponent } from './create-programa/create-programa.component';
import { CreateSeccionComponent } from './create-seccion/create-seccion.component';
import { IndexProgramaComponent } from './index-programa/index-programa.component';
import { SeccionProgramaComponent } from './seccion-programa/seccion-programa.component';
import { UpdateProgramaComponent } from './update-programa/update-programa.component';

@NgModule({
  declarations: [
    IndexProductoComponent,
    UpdateProductoComponent,
    InventarioProductoComponent,
    VariedadProductoComponent,
    GaleriaProductoComponent,
    CreateProductoComponent,
    ReviewsProductoComponent,
    CreateProgramaComponent,
    CreateSeccionComponent,
    IndexProgramaComponent,
    SeccionProgramaComponent,
    UpdateProgramaComponent,       
],
  imports: [
    CommonModule,
    ProductosRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    // Ng2SmartTableModule    
    FormsModule,
    CKEditorModule,
    Ng2SearchPipeModule,
    SharedModule,
    NgxTinymceModule.forRoot({
      baseURL:'../../../assets/tinymce/'
    })
    
  ]
})
export class ProductosModule { }