import { Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { ProductoService } from 'src/app/services/producto.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from 'src/app/shared/service/table.service';
import { DecimalPipe } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';

declare var iziToast;
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-seccion-programa',
  templateUrl: './seccion-programa.component.html',
  //styleUrls: ['./seccion-programa.component.scss']
  providers: [TableService, DecimalPipe],
})
export class SeccionProgramaComponent implements OnInit {

  @ViewChild('closebutton') closebutton;

  public closeResult: string;

  public item2 : any = {};
  public producto :any = {};
  public seccion :any = {};
  public secciones :any = {};
  public video :any = {};  
  //public preview :any = {};

  public titulo : '';
  public posicion : '';
  public descripcion : '';

  public id;
  public token;  

  public file : File = undefined;
  public load_btn = false;
  public load_btn_eliminar_video = false;
  public load_btn_eliminar_seccion = false;
  public load_btn_actualizar_seccion = false;
  public load_btn_subir_video = false;
  
  public url;

  constructor(
    private _route: ActivatedRoute,
    private _productoService: ProductoService,
    private modalService: NgbModal,
    public service: TableService
  ) {
    this.token = localStorage.getItem('token');
    this.url = GLOBAL.url;
    this._route.params.subscribe(
      params=>{
        this.id = params['id'];
        
        this.init_data();
      }
    )
  }

  init_data(){

    this._productoService.obtener_secciones_programa_admin(this.id,this.token).subscribe(
      response=>{
        if(response == undefined){                    
          this.secciones = undefined;          
        }else{
          this.secciones = response.data;              
        }               
      },           
    
    )

    this._productoService.obtener_producto_admin(this.id,this.token).subscribe(
      response=>{
        if(response == undefined){
          this.producto = undefined;          
        }else{
          this.producto = response.data;          
        }
      },
    )
  }

  ngOnInit(): void { 

  }
   registro(registroForm){ //Se registra una seccion
    if(registroForm.valid){  

          if(this.seccion.titulo != undefined){

            //TENGO QUE CREAR UNA NUEVA SECCION Y OBTENER SU ID            

            this.seccion.producto = this.producto;         
            this.seccion.videos = [];

            this._productoService.registro_seccion_admin(this.seccion,this.token).subscribe(
              response=>{          
                this.init_data();                 
              }
            ) 
            
            registroForm.reset();            
             
          }else{
            iziToast.show({
                title: 'ERROR',
                titleColor: '#FF0000',
                color: '#FFF',
                class: 'text-danger',
                position: 'topRight',
                message: 'Debe ingresar los valores en los campos',        
            });
          }        
    }else{
      iziToast.show({
          title: 'ERROR',
          titleColor: '#FF0000',
          color: '#FFF',
          class: 'text-danger',
          position: 'topRight',
          message: 'Los datos del formulario no son validos',        

      });

      this.load_btn = false;

    }
  }

  fileChangeEvent(event:any):void{
    var file;    
    
    if(event.target.files && event.target.files[0]){
      file = <File>event.target.files[0];      
    }else{
      iziToast.show({
          title: 'ERROR',
          titleColor: '#FF0000',
          color: '#FFF',
          class: 'text-danger',
          position: 'topRight',
          message: 'No hay una imagen de envío',        

      });      
      this.file = undefined;
    }
    this.file = file;
  }

  fileChangeEvent2(event:any):void{
    //var preview;    
    
    if(event.target.files && event.target.files[0]){
      //preview = <File>event.target.files[0];      
    }else{
      iziToast.show({
          title: 'ERROR',
          titleColor: '#FF0000',
          color: '#FFF',
          class: 'text-danger',
          position: 'topRight',
          message: 'No hay un archivo de envío',        

      });      
      //this.preview = undefined;
    }
    //this.preview = preview;
  }

  parar(vid: HTMLMediaElement){
    vid.pause();
  }

  subir_video(idSeccion){    
    
    if(this.file != undefined){
      this.load_btn_subir_video = true;
      let data = {
        titulo: this.video.titulo,
        posicion: this.video.posicion,
        video: this.file,
        //preview: this.preview,
        free: false,
        _id: uuidv4(),
      }      
      
      this._productoService.agregar_video_seccion_admin(idSeccion,data,this.token).subscribe(
        response=>{          
          this.init_data(); 
          this.modalService.dismissAll();
          this.video.titulo = '';
          this.video.posicion = '';
          this.file = null;
          this.load_btn_subir_video = false;
        }
      )      
    }else{
      iziToast.show({
          title: 'ERROR',
          titleColor: '#FF0000',
          color: '#FFF',
          class: 'text-danger',
          position: 'topRight',
          message: 'Debe seleccionar un video para subir',        
      });
      this.load_btn_subir_video = false;
    }
  }

  eliminar_seccion(id){    
    console.log('ENTRO ACA!!');
    this.load_btn_eliminar_seccion = true;
    this._productoService.eliminar_seccion_programa_admin(id,this.token).subscribe(
      response=>{
        iziToast.show({
            title: 'SUCCESS',
            titleColor: '#1DC74C',
            color: '#FFF',
            class: 'text-success',
            position: 'topRight',
            message: 'Se eliminó correctamente la sección.',        
        });

        this.modalService.dismissAll();
        this.load_btn_eliminar_seccion = false;
        this.init_data(); 
        
      },
      error=>{
        iziToast.show({
            title: 'ERROR',
            titleColor: '#FF0000',
            color: '#FFF',
            class: 'text-danger',
            position: 'topRight',
            message: 'Ocurrió un error en el servidor.',        
        });        
        this.load_btn_eliminar_seccion = false;
        
      }
    )
  }

  guardar_item(i){    
    var data : any = {};

    data.titulo = i.titulo;
    data.posicion = i.posicion;
    data.descripcion = i.descripcion;
    this.item2 = data;
  }

  actualizar_seccion(id){    
    this.load_btn_actualizar_seccion = true;

    var data : any = {};

    data.titulo = this.item2.titulo;
    data.posicion = this.item2.posicion;
    data.descripcion = this.item2.descripcion;    
    
    this._productoService.actualizar_seccion_programa_admin(id,data,this.token).subscribe(
      response=>{
        iziToast.show({
            title: 'SUCCESS',
            titleColor: '#1DC74C',
            color: '#FFF',
            class: 'text-success',
            position: 'topRight',
            message: 'Se actualizó correctamente la sección.',        
        });

        this.modalService.dismissAll();
        this.load_btn_actualizar_seccion = false;
        this.init_data();
        
      },
      error=>{
        iziToast.show({
            title: 'ERROR',
            titleColor: '#FF0000',
            color: '#FFF',
            class: 'text-danger',
            position: 'topRight',
            message: 'Ocurrió un error en el servidor.',        
        });        
        this.load_btn_actualizar_seccion = false;        
      }
    )
  }

  eliminar_video(idSeccion,video_data){    
    this.load_btn_eliminar_video = true;
    this._productoService.eliminar_video_seccion_programa_admin(idSeccion,video_data,this.token).subscribe(
      response=>{
        iziToast.show({
            title: 'SUCCESS',
            titleColor: '#1DC74C',
            color: '#FFF',
            class: 'text-success',
            position: 'topRight',
            message: 'Se eliminó correctamente el video.',        
        });

      this.modalService.dismissAll();
      this.load_btn_eliminar_video = false;
      this.init_data();
        
      },
      error=>{
        iziToast.show({
            title: 'ERROR',
            titleColor: '#FF0000',
            color: '#FFF',
            class: 'text-danger',
            position: 'topRight',
            message: 'Ocurrió un error en el servidor.',        
        });        
        this.load_btn_eliminar_video = false;        
      }
    )
  }

  change_free_video(idSeccion,video,valor){   
    
    this._productoService.eliminar_video_seccion_programa_admin(idSeccion,{_id:video._id},this.token).subscribe(
      response=>{
        let data = {
          titulo: video.titulo,
          posicion: video.posicion,
          video: video.video,      
          free: valor,
          _id: video._id,
        }    
        
        this._productoService.agregar_video_seccion_admin(idSeccion,data,this.token).subscribe(
          response=>{
            this.modalService.dismissAll();
            this.init_data();
          }
        );        
      }
    );    
  }

  open(content,item?) {
    if (item !== undefined) {
      this.guardar_item(item);
    } 
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public onSave() {
    this.closebutton.nativeElement.click();
  }
  

}
