import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AdminGuard } from 'src/app/guards/admin.guard';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'default', component: DashboardComponent, canActivate: [AdminGuard],
        data: {
          title: "Dashboard",
          breadcrumb: "Dashboard"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
