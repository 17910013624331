import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { BlogService } from 'src/app/services/blog.service';

declare var iziToast;
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit-blog.component.html',  
})
export class EditBlogComponent implements OnInit {

  @ViewChild('topicInputRef') topicInputRef: ElementRef;


  public blog : any = {
    title : '',    
    text1: '',
    text2: '',
    quiz: [],
    tags: [] as { name: string }[]
  };

  availableTags: { name: string }[] = [
    { name: 'Tag1' },
    { name: 'Tag2' },
    { name: 'Tag3' }
  ]; // Aquí cargarás los tags preexistentes desde tu base de datos
    
  // Tags seleccionados por el usuario
  selectedTag: { title: string, _id: string } | null = null; // Usa null para mostrar el placeholder
  selectedTags: { title: string, _id: string }[] = [];
  
  public file1 : File = undefined;
  public file2 : File = undefined;
  public file3 : File = undefined;
  //public imgSelect : any | ArrayBuffer = 'assets/img/01.jpg';  
  public token;
  public load_btn = false;  
  public previewImage1: string | ArrayBuffer | null = 'assets/images/about-main.jpg'; // Imagen de muestra inicial 1
  previewImage2: string | ArrayBuffer | null = 'assets/images/blog/1.jpg'; // Imagen de muestra inicial 2
  previewImage3: string | ArrayBuffer | null = 'assets/images/blog/2.jpg'; // Imagen de muestra inicial 3

  topicInput: string = ''; 
  newTag: string = '';

  public config_global : any = {};
  public id;


  
  



  constructor(
    private _adminService : AdminService,
    private _blogService : BlogService,
    private _router : Router,
    private _route : ActivatedRoute,
  ) {
    this.token = this._adminService.getToken();
    this._adminService.obtener_config_publico().subscribe({
      next: (response)=>{ this.config_global = response.data}
    })
  }

  ngOnInit(): void {
    this._route.params.subscribe(
      params=>{
        this.id = params['id'];
        console.log(this.id);
        this._blogService.obtener_blog_admin(this.id,this.token).subscribe(
          response=>{
            if(response == undefined){
              this.blog = undefined;
            }else{
              this.blog = response.data;
              console.log(this.blog);
              //this.imgSelect = this.url +'obtener_portada/'+ this.producto.portada;              
            }            
          }
        ),
        error=>{
          console.log(error);          
        }        
      }
    )
  }

  registro(registroForm){
    if(registroForm.valid){      
      this.load_btn = true;      
      this._blogService.registro_blog_data_admin(this.blog,this.token).subscribe(
        response=>{        
          this._blogService.add_blog_image_admin(response.data._id,this.file1,this.file2, this.file3, this.token).subscribe(
            response=>{
              iziToast.show({message: 'Se registro correctamente el nuevo blog.',title: 'SUCCESS',titleColor: '#1DC74C',color: '#FFF',class: 'text-success',position: 'topRight',});
              this.load_btn = false;
              this._router.navigate(['/blogs/index-blog']); 
            }
          )
        },
        error=>{            
          this.load_btn = false;
        }
      );
      
    }else{
      iziToast.show({message: 'Debe llenar todos los campos del formulario para registrar el blog',title: 'ERROR',titleColor: '#FF0000',color: '#FFF',class: 'text-danger',position: 'topRight',});
      this.load_btn = false;
      $('#input-portada').text('Seleccionar imagen');
      this.previewImage1 = 'assets/images/about-main.jpg'; // Imagen de muestra inicial 1
      this.file1 = undefined;  
    }
  } 

  //************************TOPICS******************* */

  handleEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    const inputValue = this.topicInputRef.nativeElement.value;
    if (inputValue && inputValue.trim()) {
        this.addQuizTopic(inputValue.trim());
    }
  }

  addQuizTopic(topic: string) {
    this.blog.quiz.push(topic);
    this.topicInputRef.nativeElement.value = ''; // Limpiar el campo de entrada
  }
  
  removeQuizTopic(topic: string) {
    this.blog.quiz = this.blog.quiz.filter(t => t !== topic);
  }


  //******************TAGS*********************** */
  addTag() {
    if (this.selectedTag && !this.selectedTags.some(tag => tag._id === this.selectedTag!._id)) {
        this.selectedTags.push(this.selectedTag);
        this.blog.tags.push(this.selectedTag); // Agregar a this.blog.tags
        this.selectedTag = null; // Limpiar la selección después de agregar
    }
}

removeTag(tag: { title: string, _id: string }) {
    this.selectedTags = this.selectedTags.filter(t => t._id !== tag._id);
    this.blog.tags = this.blog.tags.filter(t => t._id !== tag._id); // Eliminar de this.blog.tags
}

//***********************IMAGES********************* */
fileChangeEvent(event: any, previewImageName: string, fileVarName: string): void {
  let file_aux;
  if (event.target.files && event.target.files[0]) {
    file_aux = <File>event.target.files[0];
  } else {
    this.showErrorMessage('No hay una imagen de envío');
    this.resetImage(previewImageName, fileVarName);
    return;
  }

  if (file_aux.size <= 4000000) { // Tamaño de la imagen <= 4MB
    if (['image/png', 'image/webp', 'image/jpg', 'image/gif', 'image/jpeg'].includes(file_aux.type)) {
      const reader = new FileReader();
      reader.onload = e => this[previewImageName] = reader.result;
      reader.readAsDataURL(file_aux);

      $('#input-portada').text(file_aux.name);
      this[fileVarName] = file_aux;
    } else {
      this.showErrorMessage('El archivo debe ser una imagen');
      this.resetImage(previewImageName, fileVarName);
    }
  } else {
    this.showErrorMessage('El archivo no puede superar los 4 MB');
    this.resetImage(previewImageName, fileVarName);
  }
}

showErrorMessage(message: string): void {
  iziToast.show({title: 'ERROR',titleColor: '#FF0000',color: '#FFF',class: 'text-danger',position: 'topRight',message: message,});
  $('#input-portada').text('Seleccionar imagen');
}

resetImage(previewImageName: string, fileVarName: string): void {
  this[previewImageName] = 'assets/images/about-main.jpg'; // Imagen de muestra inicial
  this[fileVarName] = undefined;
}



}
